@import 'campaign';
@import 'stickers';
@import 'shops';
@import 'terms';
@import 'settings';
@import 'payment-providers';

.pick-n-pay {
    .result-container{
        margin: var(--gutter) 0;
    }
    
}
.widgets {
    display: grid;
    
    gap: var(--gutter);
    .widget {
        h2{
            margin-top: 0;
        }
        background-color: white;
        border-radius: 10px;
        padding: 20px;
        box-shadow: 0px 0px 10px 0px #E4E4E4;
        max-width: 500px;
        ul{
        list-style: none;
        padding: 0;
        li{
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;
            .label{
            font-weight: bold;
            }
        }
     }
  }
}

  .post-install-tasks{
    min-height: 250px;
    font-weight: bold;
    ol {
        margin: 20px;
    }
    
    li{
        margin: 10px 0;
        &.task-done a{
            @include icon(check, after, 5px){
                color: green;
                font-size: 25px;
                text-decoration: none;
            }
        }
    }

    .ready-for-live{
        font-size: 25px;
        font-weight: bold;
        @include icon(rocket){
            font-size: 45px;
        }
    }
    background: url(../images/no-rocket.svg) no-repeat bottom right, #39b0a9;
    background-size: 40%;
    &.all-tasks-done{
        background: url(../images/rocket.svg) no-repeat bottom right, #39b0a9;
        background-size: 40%;
        color: white;
        a {
            color: white;
        }
    }
  }