.pick-n-pay {
    .filter {
      display: flex;
      flex-flow: row wrap;
      gap: 20px;  
      align-items: center;
      .form-select {
        min-width: 200px;
      }
    }
    &.stickers{
      .sticker-picker{
        .button-text{
          display: none;
        }

      }
    }
  }
  .sticker-picker {
    position: relative;
    
    .sticker-picker-dialog {
      position: absolute;
      top: 40px;
      right: 0;
      z-index: 1000;
  
      min-width: 600px;
      min-height: 300px;
  
      background-color: white;
      border-radius: 15px;
      border: solid 2px #d5d5d5;
      
      padding: 20px;
  
      .sticker-picker-dialog-header {
        display: flex;
        justify-content: space-between;
        h1{
          font-size:1.2rem;
        }
        margin-bottom: 20px;
      }
      
      .sticker-picker-dialog-body{ 
        min-height: 200px;
        max-height: 300px;
        overflow-y: auto;
        overflow-x: hidden;
        border-radius: 10px;
        border: solid 2px #d5d5d5;
        background-color: #f9f9f9;
        max-width: 800px;
        //overflow: auto;
        ul {
          list-style: none;
          padding:10px;
          display: flex;
          flex-flow: row wrap;
          gap: 10px;
          .sticker{
            position: relative;
            .btn-edit{
              position: absolute;
              top: -20px;
              right: -20px;
              background-color: white;
              box-shadow: var(--form-box-shadow);
              border-radius: 50%;
              width: 40px;
              height: 40px;
              display: flex;
              align-items: center;
              justify-content: center;
              opacity: 0;
              transition: all 0.3s ease-out;
              cursor: pointer;
              &:before{
                margin: 0;
              }
            }
            &:hover .btn-edit{
              opacity: 1;
              top: -15px;
              right: -15px;
              z-index: 1;
            }
          }
          .sticker-inner{
            border-radius: 10px;
            box-shadow: var(--form-box-shadow);
            background-color: #fff;
            min-width: 100px;
            min-height:40px;
            display: flex;
            align-items: center;
            padding: 5px 10px;
            font-weight: bold;
            img {
              max-width: 50px;
              max-height: 50px;
              margin-right: 10px;
            }
            
          }
          input{
            display: none;
          }
          input:checked + .sticker-inner{
            background-color: var(--color-primary);
            color: white;
          }
        }
      }
      .sticker-picker-dialog-create-pane{
        padding: 20px 0;
        background-color: white;
        display: flex;
        justify-content: center;
        
        .sticker-picker-create-pane{
          display: flex;
          justify-content: space-between;
          gap: 20px;
          width: 100%;
          > form {
            flex: 1;
          }
          .input-group{
            margin-bottom: 20px;
            display: flex;
            input{
              flex: 1;
            }
          }
          .form-footer{
            justify-content: end;
          }
        }
      }
      .sticker-picker-dialog-footer{
        display: flex;
        justify-content: end;
      }
    }
    .image-upload{
      .drop-area{
        width: 150px;
        height: 150px;
        aspect-ratio: 1 / 1;
        img{
          max-width: 150px;
          max-height: 150px;
        }
        form{
          span{
            display: none;
          }
        }
      }
    }
  }
.search-result {
  display: table;
  table-layout: auto;
  border-collapse: collapse;
  list-style: none;
  margin: var(--gutter);
  background-color: white;
  border-radius: 15px;
  padding: 0;

  li.article {
    display: table-row;
    border-bottom: solid 1px #d5d5d5;
    
    padding: 15px;

    h1 {
        font-size: 1rem;
        margin: 0;
        //width: 30%;
    }

    &:last-child {
      border-bottom: none;
    }
    > * {
        display: table-cell;
        padding: 10px 20px;
        vertical-align: middle;
    }

    .article-stickers{
      list-style: none;
      display: flex;
      gap: 10px;
      margin-top: -7px;
      margin-bottom: -10px;
      img{
        
        max-width: 50px;
        max-height: 50px;
      }
    }

    .sticker-picker{
        width: 1%;
    }
  }

}