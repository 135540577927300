.user-list, .users{
    width: 100%   
}
.user-list.list {
    background-color: white;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    li:first-child {
      border-top: none;
    }
    li:last-child {
      border-bottom: none;
    }
}

.user-header{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    
}
.user-item{
    display: flex;
    gap: 20px;
    flex-flow: row wrap;
    .user{
        flex: 20%;
        span{
            display: block;
        }
        .user-email{
            font-size: small;
            padding-top: 5px;
        }
    }
    .access {
        flex: 20%;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: flex-start;
        gap: 10px;
    }
    .profitcenters{
        display: flex;
        flex-flow: row wrap;
        list-style: none;
        gap: 10px;
        flex: 48%;
        max-height: 100px;
        overflow-y: auto;
        overflow-x: hidden;


        li{
            white-space: nowrap;
            font-size: 0.85rem;
        }
    }
    .user-roles {
        list-style: none;
        padding: 0;
        max-width: 320px;
        li{
            display: inline-block;
            margin: 5px;
        }
    }
    .badge{
        pointer-events: none;
    }
}

.edit-user, .create-user{
    .user-info, .user-profitcenter-access{
        background-color: white;
        border-radius: 10px;
        
   }
   .user-info{
        padding: 20px;
        flex: 1 60%;
        display: flex;
        flex-flow: column;
        gap: 20px;
        .checkbox-wrapper{
            margin: 0;
        }
   }
    .user-profitcenter-access{
        flex: 1 30%;
        h2, button{
            margin: 20px 10px;
        }
    }
    .btn-group {
        margin-top: 20px;
    }
}
.profitceter-dialog{
    width: 90%;
    max-width: 500px;
    .modal-title{
        font-weight: bold;
    }
    .list-item{
        display: flex;
        align-items: center;
        cursor: pointer;
        &.selected{
            svg{
                fill: var(--color-success);
                width: 2rem;
                height: 2rem;
                margin: -0.5rem 0 -0.5rem -0.5rem;
            }
        }
    }
}
