.modal-backdrop {
    background-color: rgba(17, 12, 46, 0.35);
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 1;
    &.fade{
        opacity: 0;
    }
    &.show{
        opacity: 1;
    }
}
.modal{
   position: fixed;
   z-index: 1;
   top:0;
   bottom: 0;
   left: 0;
   right: 0;
}
.modal-dialog {
    background-color: white;
    border-radius: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    
}
.modal-content{
    height: 80vh;
    display: flex;
    flex-direction: column;
    //overflow: hidden;
}
.modal-header{
    display: flex;
    justify-content: space-between;
    
    padding: 10px;
}
.modal-body{
   // height: 200px;
   flex: 1 1 auto;
   overflow-x: none;
   overflow-y: auto;
    .list-group-item{
        cursor: pointer;
    }
}
.modal-footer {
    padding: 10px;
    display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
}
.fade {
    transition: opacity .15s linear;
    &:not(.show) {
        opacity: 0;
    }
}

.confirm-dialog{
    overflow: hidden;
    .modal-header{
        background-color: var(--background-color-navbar);
        color: white;
    }
    .modal-content{
        //padding: 20px;
        height: auto;
    }
    .modal-body{
        padding: 0 10px;
    }
}