
.edit-price{
  .temp-footer{
      margin: var(--gutter) 0
  }
}

.campaign-stickers {
   
   
  @extend .article-stickers;
  li {
    display: inline-block;
  }
  .sticker-image {
    width:40px;
    height: 40px;
    border: none !important;
  }
}


.settings {
  > h3 {
    margin-top: var(--gutter);
  }
  .input-group {
    margin-bottom: 10px;
  }
  .select-deployment-mode {
    min-width: 200px;
  }
  .settings-group {
    max-width: 800px;
    background-color: white;
    padding: 20px;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    margin-bottom: 10px;
    
  }
}
.btn-confirm-container {
  display: inline-block;
  position: relative;
  
  .btn-confirm-dialog {
    min-width: 400px;
    position: absolute;
    background-color: white;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    padding: 20px;
    z-index: 1000;
    bottom: 30px;
    right: -30px;
    animation: zoomFadeInAnimation 600ms;
    p {
      margin-top: 0;
    }
    button {
      margin: 5px;
    }
    .dialog-footer{
      text-align: right;
    }
  }
}


.paging {
  list-style: none;
    li {
      cursor: pointer;
      border: solid 1px black;
      border-radius: 2px;
      padding: 5px;
        display: inline-block;
        margin-right: 5px;
      &.active {
        background-color: black;
        color: white;
      }
    }
  
}


/*.navbar .locale-selector {
  .btn-locale.active{
    opacity: 1.0;
  }
}*/


//** blurring numbers*//

/*.total-value, .total-qty, .value, .compare-value, .compare-qty{
  filter: blur(5px);
  opacity: 0.6;
}*/
//** blurring *//
