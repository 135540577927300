@mixin for-phone-only {
  @media (max-width: 599px) {
    @content;
  }
}

@mixin for-tablet-portrait-down {
  @media (max-width: 599px) {
    @content;
  }
}

@mixin for-tablet-portrait-up {
  @media (min-width: 600px) {
    @content;
  }
}


@mixin for-tablet-landscape-down {
  @media (max-width: 899px) {
    @content;
  }
}

@mixin for-tablet-landscape-up {
  @media (min-width: 900px) {
    @content;
  }
}

@mixin for-laptop-down {
  @media (max-width: 1159px) {
    @content;
  }
}

@mixin for-laptop-up {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin for-desktop-down {
  @media (max-width: 1399px) {
    @content;
  }
}

@mixin for-desktop-up {
  @media (min-width: 1400px) {
    @content;
  }
}

@mixin for-big-desktop-down {
  @media (max-width: 1799px) {
    @content;
  }
}

@mixin for-big-desktop-up {
  @media (min-width: 1800px) {
    @content;
  }
}

@mixin cross($size: 20px, $color: currentColor, $thickness: 1px) {
  margin: 0;
  padding: 0;
  border: 0;
  background: none;
  position: relative;
  width: $size;
  height: $size;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: ($size - $thickness) / 2;
    left: 0;
    right: 0;
    height: $thickness;
    background: $color;
    border-radius: $thickness;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }

  span {
    display: block;
  }

}

@mixin icon($icon: false, $position: before, $margin: 1rem) {
  

  @if $position == both {
      $position: 'before, &:after';
  }
  // Either a :before or :after pseudo-element, or both, defaulting to :before
  &:#{$position} {
      
      @if $icon {
          // A particular icon has been specified
          content: "#{map-get($icons, $icon)}";
      }
      // Include any extra rules supplied for the pseudo-element
      font-family: "dashboard-icons";
      font-style: normal;
      font-weight: normal;
      speak: never;

      display: inline-block;
      text-decoration: inherit;
      width: 1em;
      text-align: center;
      /* opacity: .8; */

      /* For safety - reset parent styles, that can break glyph codes*/
      font-variant: normal;
      text-transform: none;

      /* fix buttons height, for twitter bootstrap */
      line-height: 1em;

      /* Animation center compensation - margins should be symmetric */
      /* remove if not needed */

      /* you can be more comfortable with increased icons size */
      /* font-size: 120%; */

      /* Font smoothing. That was taken from TWBS */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;

      @if $position == before{
        margin-right: $margin;
      }
      @if $position == after{
        margin-left: $margin;
      }
      @if $position == both{
        margin-right: $margin;
        margin-left: $margin;
      }
      @content;
  }
}
// sass map listing icons in use
$icons: (
  left-open:  \e800,
  plus:  \e801,
  minus:  \e802,
  calendar:  \e803,
  hart:  \e804,
  search:  \e805,
  print:  \e806,
  trash:  \e807,
  pen:  \e808,
  down-open:  \e809,
  right-open:  \e80a,
  up-open:  \e80b,
  clock:  \e80c,
  cancel:  \e80d,
  check:  \e80e,
  globe:  \e80f,
  basket:  \e810,
  image:  \e811,
  user-add:  \e812,
  menu:  \e813,
  hart-filled:  \e814,
  star-empty:  \e815,
  star:  \e816,
  block:  \e817,
  chart-bar:  \e818,
  chart-area:  \e819,
  money:  \e81a,
  attention:  \e81b,
  cog:  \e81c,
  warning-empty:  \e81d,
  avatar:  \e81e,
  align-left:  \e81f,
  bold:  \e820,
  font:  \e821,
  italic:  \e822,
  align-center:  \e823,
  align-right:  \e824,
  link-ext:  \f08e,
  list-bullet:  \f0ca,
  list-numbered:  \f0cb,
  strike:  \f0cc,
  underline:  \f0cd,
  header:  \f1dc,
  ccw: \e825,
  cw: \e826,
  link: \e827,
  megaphone: \e828,
  rocket: \e829,
  paper-plane: \e82a,
  resize-full: \e82b,
  resize-small: \e82c,
  unlink: \f127
);

@mixin selectebleListStyle(){
  > li{
    padding: 10px;
    display: flex;
    gap: 10px;
    border-top: 1px solid rgb(178, 178, 178);
    cursor: pointer;
    border-left: 0px solid var(--color-primary);
    transition: border-left-width 0.3s;
    &.selected {
      background: var(--color-primary);
      color: var(--color-primary-contrast);
    }
    &:hover, &:focus{
      border-left-width: 5px;
    }
  }
}

@keyframes zoomFadeInAnimation {
  0% {
    scale: 0.8;
      opacity: 0;
  }
  100% {
    scale: 1;
      opacity: 1;
  }
}



@keyframes fadeInAnimation {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}


@keyframes localeEditMove {
  0% {
    top: 10px;
    right: 10px;
    z-index: 1;
  }
  49% {
    top: -9px;
    right: -9px;
    z-index: 1;
  }
  50% {
    top: -9px;
    right: -9px;
    z-index: 3;
  }
  100% {
    top: -3px;
    right: -3px;
    z-index: 3;
  }
}
@keyframes localeEditMoveBack {
  0% {
    top: -3px;
    right: -3px;
    z-index: 3;
  }
  49% {
    top: -9px;
    right: -9px;
    z-index: 3;
  }
  50% {
    top: -9px;
    right: -9px;
    z-index: 1;
  }
  100% {
    top: 10px;
    right: 10px;
    z-index: 1;
  }
}