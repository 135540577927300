.user-profile {
  .user-profile-header {
    h1 {
      display: block;
      margin: 0 0 var(--gutter);
    }
  }
  .profile-form{
    background-color: white;
    display: block;
    margin: var(--gutter) 0;
    padding: 20px;
    border-radius: 10px;

    > span{
      display: block;
      padding: 5px 0;
    }
  }
  .user-information{
    ul{
      max-width: 800px;
      background-color: white;
      padding: 20px;
      border: 1px solid #e0e0e0;
      border-radius: 10px;
      margin-bottom: 10px;

      list-style: none;
      
      display: flex;
      flex-flow: row wrap;
      gap: 5px 10px;
      li:not(:last-child):after{
        content: ",";
      }
    }
  }
}