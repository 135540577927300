.terms-of-service{
    max-width: 800px;
    
    .editor-container{
        margin: 10px 0;
        max-width: 100%;
        
        .editor-input{
            min-height: 50vh;
        }
    }
    .terms-footer, .locale-selector {
        text-align: right;
    }
}