/// <reference path="../mixins.scss" />
.stats-summary{
    display: flex;
    justify-content: space-between;
    gap: var(--gutter);
    padding: 10px 0 30px;
    flex-flow: row wrap;
    //background-color: var(--color-primary);
    //padding: 20px 0;
}
.stats-summary-item{
    display: flex;
    color: #565656;
    //text-align: center;
    background-color: white;
    border-radius: var(--border-radius);
    padding: 10px;
    //box-shadow: var(--form-box-shadow);
    .header{
        margin: 0;

    }

    .content{
        > *:not(:last-child) {
            margin-bottom: 10px;
        }
    }
    
    &:before{
        content: "";
        display: block;
        border-radius: 5px;
        width: 10px;
        background-color: red;
        margin-right: 10px;
    }
    .value {
        display: block;
        font-weight: bold;
        //padding: 20px 0 5px;
    }
    .compare-value, .compare-percentage, .compare-quantity {
        font-weight: bold;
        padding-top: 5px;
        &.text-danger{
            color: var(--color-danger);
        }
        &.text-success{
            color: var(--color-success);
        }
    }
    $my-colors: (#EAB69F, #E07A5F, #81B29A, #F2CC8F, #2A9D8F, #F4A261, #287271);

    @for $colIndex from 1 through length($my-colors) {
        &:nth-child(#{length($my-colors)}n + #{$colIndex}):before{
            background-color: nth($my-colors, $colIndex);
        }
    }
}
/*max-width: 599px*/
@include for-tablet-portrait-down {}

/*min-width: 600px*/
@include for-tablet-portrait-up {}

/*max-width: 899px*/
@include for-tablet-landscape-down {}

/*min-width: 900px*/
@include for-tablet-landscape-up {
    
}

@include for-laptop-up{

}

.tab-panel-header{
    > h2 {
        display: flex;
        flex-flow: row wrap;
        align-items: baseline;
        gap: 5px;
    }
    .stats-summary-item{
        /*min-width: 900px*/
        @include for-laptop-down {
            width: 250px;
            .value {
                //font-size: 1cqmax;
            }
            
        }
        .value {
            font-size: 1.5rem;
            
        }
        @include for-phone-only{
            width: 40%;
            .value{
                font-size: 1.5rem;
            }   
        }
        
    }
    .stats-summary-year{ // TODO: Fix this.. try to fit the text to det containers again..
        .value {
            font-size: 1.5rem;
            @include for-phone-only{
                font-size: 1.3rem;
            }
            
        }
    }
    .stats-summary-month{
        .value {
            font-size: 1.5rem;
            @include for-phone-only{
                font-size: 1.3rem;
            }
            
        }
    }
}
.terminal-sales{
    background-color: white;
    border-radius: 10px;
    margin: 10px 0;
    padding: 20px;
    box-shadow: 0 2px 6px rgba(154,153,153,0.75);
    color: var(--color-primary);
    text-align: center;
    .header{
        font-size: 1.3rem;
    }
    .content{
        display: flex;
        flex-flow: row wrap;
        .row-statistic-value{
            order: 1;
            flex: 1 100%;
            font-size: 2rem;
            
        }
        .value-stat{
            margin: 0 auto;
            order: 2;
            display: flex;
            flex-flow: row wrap;
            gap: 20px;
            font-size: 1.2rem;
        }

    }
}

.terminal-sales-list {
    .compare-value, .compare-percentage, .compare-quantity {
        &.text-danger{
            color: var(--color-danger);
        }
        &.text-success{
            color: var(--color-success);
        }
    }
}

g.recharts-layer {
    &.recharts-bar-rectangle path{
        fill: var(--chart-bar-color);
        //stroke: red;
    }
    &.recharts-line path {
        stroke: var(--chart-line-color);
    }
} 

.custom-tooltip{
    //position: absolute;
    //top: 0;
    //right: 100%;
    background-color: white;
    padding: 1px 10px;
    border-radius: 10px;
    box-shadow: 0 2px 6px rgba(154,153,153,0.75);
}