.list, .list-group{
    list-style: none;
    padding: 0;
    margin: 0;
    > li{
        border-bottom: 1px solid rgb(184, 184, 184);
        border-left: 3px solid transparent; 
        //justify-content: space-between;
        padding: 10px;
        &:hover, &:active, &:focus{
            border-bottom-color: rgb(89, 89, 89);
            border-left-color: #006086;
        &:nth-child(even){
            border-bottom-color: rgb(89, 89, 89);
            
        }
        }
        &:nth-child(even){
            background-color: rgb(232, 232, 232);
        
        }
        &:first-child{
            border-top: 1px solid rgb(184, 184, 184);
        }
    }
    .list-group-item{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}
.list-group{

}