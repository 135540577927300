
.campaign-stickers {
    @extend .article-stickers;
    li {
        display: inline-block;
    }
    .sticker-image {
        width:40px;
        height: 40px;
        border: none !important;
    }
}