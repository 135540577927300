.alert{
    display: flex;
    flex-flow: row wrap;
    border-radius: 10px;
    color: #004085;
    background-color: #cce5ff;
    border-color: #b8daff;
    gap: 20px;
    //justify-content: space-between;
    align-items: center;
    //border: 1px solid rgb(93, 50, 234);
    padding: 1.5rem 1rem;
    margin: 5px 0;
    position: relative;
    p{
        margin: 0;
    }
    > *:first-child{
        margin-left: 60px !important;
    }
    @include icon('warning-empty', 'before', 1rem){
        font-size: 40px;
        //mix-blend-mode: overlay;
        position: absolute;
        //top: 20px;
    }

    &-danger{
        color: #721c24;
        background-color: #f8d7da;
        border-color: #f5c6cb;
    }
    &-success{
        color: #155724;
        background-color: #d4edda;
        border-color: #c3e6cb;
        @include icon('check')
    }
    .btn{
        justify-self: end;
    }
    ul{
        //list-style: none;
        li{
            margin: 10px;
        }
    }
}