.payment-providers {
    //max-width: 800px;
    .filter{
      justify-content: space-between;
      .btn-add{
        &:before{
          font-size: 2rem;
        }
      }
    }
    .create-panel {
      background-color: white;
      border: 1px solid #e0e0e0;
      border-radius: 10px;
      //padding: 20px;
      margin-bottom: 20px;
      margin-top: 20px;
      position: relative;
      h3, > .input-group{
        margin: 20px;
      }
      .provider-header {
        padding-bottom: 0;
        border-top: 1px solid rgb(196, 196, 196);
        h3 {
          width: 100%;
          &:after{
              height: 60px;
              margin: auto 0 auto auto;
          }
        }
      }
      .provider-settings{
        border: none;
        //margin-top: 20px;
        padding: 20px;
      }
      .btn-close{
        position: absolute;
        top: 20px;
        right: 20px;
      }
    }
    .provider-header{
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 20px;
    }
    .provider-settings{
      border-top: 1px solid rgb(196, 196, 196);
      //margin-top: 20px;
      padding: 20px;
    }
    ul{
      list-style: none;
    }
    .provider-list{
      list-style: none;
      padding-top: 20px;
      > li{
        background-color: white;
        border: 1px solid #e0e0e0;
        border-radius: 10px;
        margin-bottom: 5px;
      }
    }
    .provider-usage {
      flex: 1;
      display: flex;
      justify-content: end;
      flex-flow: row wrap;
      gap: 10px;
    }
    .provider {
      h3{
        margin: 0;
        display: flex;
        align-items: center;
        gap: 20px;
        &:after{
            content: "";
            display: inline-block;
            background-size: contain;
            background-repeat: no-repeat;
            height: 30px;
        }
      }
    }
    .nets-easy{
        .provider-header{
            h3{
              &:after{
                background-image: url(../images/nexi-nets.svg);
                aspect-ratio: 132/26;
              }  
            }
        }
    }
    .swedbank-pay{
        .provider-header{
            h3{
                &:after{
                    background-image: url(../images/swpay.svg);
                    aspect-ratio: 300/144;
                } 
            }
        }
    }
    .adyen{
        .provider-header{
            h3{ 
                &:after{
                    background-image: url(../images/adyen.svg);
                    aspect-ratio: 90/30;
                } 
            }
        }
    }
  }