@import 'reset.scss';
@import 'mixins';
@import 'variables.scss';
@import 'image-upload';
@import 'navbar.scss';
@import 'font.scss';
@import 'tabs.scss';
@import 'form.scss';
@import 'button.scss';
@import 'admin';
@import 'list';
@import 'prices';
@import 'dialog';
@import 'login';
@import 'stats';
@import 'accordion';
@import 'users';
@import 'loader';
@import 'toast';
@import 'profile';
@import 'dev-temp';
@import 'pnp/pick-n-pay.scss';
@import 'alert';
@import 'font';
@import 'text-editor';
@import 'locale-selector';

/* Provide sufficient contrast against white background */
body{
  font-family: var(--font-family-main);
  background-color: var(--site-background);
  padding-bottom: 20px;
}
.page{
  display: flex;
  @include for-phone-only{
    flex-direction: column;
    /*aside {
        //background: var(--background-color-navbar);
        min-height: 50px;
        //flex: auto;
        .navbar{
          flex-direction: row;
          justify-content: space-between;
        }
    }*/
    
  }
  main{
    flex: 1;
    padding: var(--gutter);
    max-width: 1600px;
    border-right: 1px solid rgb(216, 216, 216);
  }
  &:has(.confirm-open):after{
    content: "";
    background: black;
    opacity: 0.2;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
  }
}

a {
  color: var(--color-primary);
  //text-decoration: none;
}
ol, ul {
  padding: 0;
  margin: 0;
}
label{
  user-select: none;
}
.btn, .btn, .btn-link.nav-link, .form-control, .form-check-input {
  /*&:focus, &:active{
    box-shadow: 0 0 0 0.1rem white, 0 0 0 0.25rem var(--color-primary);
  }*/
  &:focus-visible {
    outline: none;
  }
} 
/*.statistic-valueueueue {
  filter:blur(4px);
}*/

.card-header {
  background-color: #006086;
  color: #fff;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
} 



.badge{ // fix this, this was used for rgioen picker but this is moved
    background-color: white;
    color: var(--font-color);
    font-weight: 500;
    //margin-left: 10px;
    border-radius: 10px;
    padding: 5px 20px;
    font-size: 0.75rem;
    text-wrap: nowrap;
    font-weight: bold;
    box-shadow: var(--form-box-shadow);
    cursor: pointer;
    &.selected{ // selected
        background-color: var(--color-primary);
        color: white;
    }
    &.primary{
      background-color: var(--color-primary);
      color: var(--color-primary-contrast);
    }
    &.warning {
      background-color: yellow;
    }
    &.info{
      background-color: gray;
      color: white;
    }
    border: 1px solid transparent;
    transition: border-color 0.6s;
    &:hover{
      border-color: var(--color-primary);
    }
}

  $layout-breakpoint-small: 960px;
  
  @media (max-width: $layout-breakpoint-small) {
    .price-row{
      .codes, .ean-code{
        display: none;
      }
     
    }
  }

