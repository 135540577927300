.accordion{
    border-radius: 10px;
    overflow: hidden;
}
/*.accordion-button{
    border: 2px solid red;
    &.collapsed{
        border: none;
    }
}*/
.accordion-button {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border: none;
    font-size: 1rem;
    cursor: pointer;
    .description{
        padding-left: 10px;
        font-size: 1.2rem;
        &:before {
            border-style: solid;
            border-width: 0.15rem 0.15rem 0 0;
            content: '';
            display: inline-block;
            height: 0.45rem;
            left: -0.5rem;
            position: relative;
            vertical-align: top;
            width: 0.45rem;
            top: 0.3rem;
            transform: rotate(135deg);
            transition: all 0.3s;
        }
    }
    &.collapsed .description{
        &:before {
            top: 0.4rem;
            transform: rotate(45deg);
            
        }
    }
    > .item-sum, .item-qty{
        >span {
            display: block;
        }
        .text-success{
            color: var(--color-success);
        }
        .text-danger{
            color: var(--color-danger);
        }
    }
}
.accordion-collapse{
    
    &.collapse{
        display: none;
    }
    &.show{
        display: block;
    }
}
.accordion-body{
    background-color: white;
    //box-shadow: rgba(0, 0, 0, 0.5) 0px 10px 36px -18px inset;
    box-shadow: inset 0px 3px 9px 0px rgba(0,0,0,0.26);
   // padding: 20px 0;
    h3{
        margin: 0;
        padding: 20px 10px 15px;
    }
}
.accordion-item{
    width: 100%;
}
.accordion-header{
    margin: 1px 0;
    
    button{
        background-color: white;
        padding: 10px;
        //border-radius: 10px;
    }
}