.btn{
    //border: none;
    cursor: pointer;
    padding: 7px 15px;
    font-weight: bold;
    border: 1px solid var(--color-primary);
    background: none;
    font-size: 0.85rem;
    margin: 5px 0;
    &-close{
        @include cross(20px, #000, 2px);  
    }
    &-secondary{
        border: 1px solid lightgray;
    }
    &-primary {
        color: #fff;
        background-color: var(--color-primary);
        border: none;
    }
    &-danger{
        background-color: var(--color-danger);
        border: none;
        color: white;
    }
    &-edit{
        font-size: 0;
        background: none;
        border: none;
        @include icon('pen', 'before', 0){
          font-size: 20px;
        }
        &.btn-primary{
            font-size: 1rem;
            display: flex;
            align-items: center;
            gap: 10px;
            padding: 10px 20px;
            background-color: var(--color-primary)
        }
    }
    
    &-delete{
        font-size: 0;
        background: none;
        border: none;
        @include icon('trash'){
          font-size: 20px;
        }
        
    }
    &-remove {
        @include icon('trash'){
            font-size: 20px;
          }
    }
    &-expander{
        background: none;
        border: none;
        @include icon('right-open', before, 0){
            font-size: 20px;
            transition: transform 0.3s;
        }
        &.expanded{
            &:before{
                transform: rotate(90deg);
            }
            
        }
    }
    &-dropdown{
        background: none;
        border: none;
        @include icon('down-open', before, 0);
    }
    
    &-add-user{
        @include icon('user-add', 'before'){
            font-size: 20px;
        }
    }
    &-save{
       @extend .btn-primary;
    }
    &-create{
        display: flex;
        align-items: center;
        @include icon('plus', 'before', 10px){
            font-size: 35px;
        }
    }
}
.expanded{
    .btn-expander{
        &:before{
            transform: rotate(90deg);
        }
    }
}
.btn-group{
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    gap: 5px;
}
.btn-outline-light{
    border: 2px solid white;
    background: transparent;
    color: white;
}
.add-user-btn{
    display: flex;
    align-items: center;
    gap: 5px;
    &::before {
        content: '+';
        font-size: 30px;
    }
    
}

.btn-picker{
    background-color: white;
    color: var(--font-color);
    font-weight: 500;
    //margin-left: 10px;
    border-radius: 10px;
    padding: 5px 20px;
    font-size: 0.75rem;
    text-wrap: nowrap;
    font-weight: bold;
    box-shadow: var(--form-box-shadow);
    cursor: pointer;
    &.selected{ // selected
        background-color: var(--color-primary);
        color: white;
    }
    border: 1px solid transparent;
    transition: border-color 0.6s;
    &:hover{
      border-color: var(--color-primary);
    }
}
.btn-add{
    background: none;
    border: none;
    display: flex;
    align-items: center;
    @include icon('plus', 'before', 0){
        font-size: 25px;
        //mix-blend-mode: overlay;
    }
}
.btn-large-add{
    display: flex;
    align-items: center;
    gap: 20px;
    background: none;
    border: none;
    @include icon('plus', 'before', 0){
        font-size: 25px;
        //mix-blend-mode: overlay;
    }
    &::before{
        //border: 1px solid #E9E9E9;
        border-radius: 50%;
        padding: 15px;
        font-size: 35px;
        box-shadow: var(--form-box-shadow);
        background-color: white;
    }


}